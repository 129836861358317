@charset "utf-8";
/*
    AMIKO font
*/

@font-face{
    font-family: 'Spoqa HanSansNeo';
    src: url(./../font/SpoqaHanSansNeo-Bold.woff2) format('woff2');
    font-weight: 600;
}
@font-face{
    font-family: 'Spoqa HanSansNeo';
    src: url(./../font/SpoqaHanSansNeo-Medium.woff2) format('woff2');
    font-weight: 400;
}
@font-face{
    font-family: 'Spoqa HanSansNeo';
    src: url(./../font/SpoqaHanSansNeo-Regular.woff2) format('woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SEBANG_Gothic_Regular';
    src: url(./../font/SEBANG_Gothic_Regular.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}